.App {
  padding: 50px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  font-size: 33px;
  color: #121314;
  -webkit-font-smoothing: antialiased;
  /* background-color: rgb(20, 39, 51); */
  text-align: left;
}

a {
  color: #00b7ff;
  margin-right: 8px;
}

p {
  font-size: 24px;
  font-weight: 300;
  line-height: 1.4;
  max-width: 28em;
}

h1 {
  font-size: 32px;
  font-weight: 500;
  margin-bottom: 0;
}
span {
  display: block;
}


.title {
  margin-bottom: 1px;
}